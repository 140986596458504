html {
  scrollbar-color: #aeafaf #ffffff;
  scrollbar-width: thin;
}

body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen", "Ubuntu", "Cantarell", "Fira Sans",
    "Droid Sans", "Helvetica Neue", sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  -ms-overflow-style: none;
  scrollbar-width: none;

  scrollbar-color: #aeafaf #ffffff;
  scrollbar-width: thin;

  /* width */
  *::-webkit-scrollbar {
    width: 10px;
  }

  /* Track */
  *::-webkit-scrollbar-track {
    background-color: #ffffff;
    border-radius: 100px;
  }

  /* Handle */
  *::-webkit-scrollbar-thumb {
    border: 2px solid transparent;
    border-radius: 100px;
    background-color: #aeafaf;
    background-clip: content-box;
  }

  /* Handle on hover */
  *::-webkit-scrollbar-thumb:hover {
    background-color: #aeafaf;
    border-radius: 100px;
  }

  /* *::-webkit-scrollbar, */
  &::-webkit-scrollbar {
    display: none;
    scrollbar-width: none;
  }
}

#root {
  position: relative;
  /* height: 100vh; */
  overflow: hidden;
  overflow-y: auto;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New", monospace;
}

.backdrop-custom {
  opacity: 0.9 !important;
}

@import url("https://fonts.googleapis.com/css2?family=Noto+Sans+Thai:wght@100;200;300;400;500;600;700&display=swap");
